#wrapper {
  opacity: 1 !important;
}

#nav a {
  opacity: 1;
}

#nav a:before, #nav a:after {
  display: none;
}

#nav a span {
  opacity: 1;
  top: 0;
}

#nav a span:after {
  display: none;
}

#main {
  box-shadow: none;
  background: none;
}

#main > .panel {
  background: #fff;
  margin-bottom: 2em;
}

#main > .panel:last-child {
  margin-bottom: 0;
}
/*# sourceMappingURL=index.e35af3f3.css.map */
